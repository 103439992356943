import Image from "next/image";
import {ListItem, Stack, VStack, UnorderedList} from "@chakra-ui/react";
import banner from "public/pricingComparison/banner.png";
import {FEATURES_LIST} from "../priceComparison.constants";

const PriceComparisonFeatures = () => {
  return (
    <Stack spacing={6} w="full">
      <VStack>
        <Image alt="100%" src={banner} width={300} />
      </VStack>
      <UnorderedList spacing={4}>
        {FEATURES_LIST.map((content, index) => {
          return (
            <ListItem fontSize="20px" key={index}>
              {content}
            </ListItem>
          );
        })}
      </UnorderedList>
    </Stack>
  );
};

export default PriceComparisonFeatures;
