"use client";

import React from "react";
import Image from "next/image";
import Link from "next/link";
import {FiArrowUpRight} from "@onemind-services-llc/react-icons-ng/fi";
import {AspectRatio, HStack, Heading, Stack, Text} from "@chakra-ui/react";
import {TEXT_VARIANTS} from "@/theme/components/text";
import {HEADING_VARIANTS} from "@/theme/components/heading";

type Props = {
  content: string;
  image: string;
  label: string;
  link: string;
};
const CloudCard = (props: Props) => {
  const {content, image, label, link} = props;
  return (
    <Stack
      borderWidth={"1px"}
      borderColor={"primary.300"}
      boxShadow={
        "0px 0px 0px 0px rgba(81, 168, 255, 0.10), 0px 8px 19px 0px rgba(81, 168, 255, 0.10), 0px 34px 34px 0px rgba(81, 168, 255, 0.09), 0px 76px 45px 0px rgba(81, 168, 255, 0.05), 0px 135px 54px 0px rgba(81, 168, 255, 0.01), 0px 210px 59px 0px rgba(81, 168, 255, 0.00)"
      }
      borderRadius={"32px"}
      justifyContent={"space-between"}
      p={"24px"}
      spacing={4}
    >
      <AspectRatio ratio={1} position={"relative"} width={"48px"}>
        <Image
          alt="Cloud cards icons"
          src={image}
          fill
          style={{objectFit: "contain", objectPosition: "center"}}
        />
      </AspectRatio>
      <Heading
        color={"#181F20"}
        fontSize={"27px"}
        fontWeight={600}
        variant={HEADING_VARIANTS.SUBTITLE1}
      >
        {label}
      </Heading>
      <Text color={"text.100"} fontSize={"16px"} variant={TEXT_VARIANTS.BODY2}>
        {content}
      </Text>
      <HStack as={Link} cursor={"pointer"} href={link} spacing={1}>
        <Text color={"text.100"} fontWeight={600} variant={TEXT_VARIANTS.LABEL1}>
          Learn more
        </Text>
        <FiArrowUpRight color="#8587B3" fontSize={"24px"} />
      </HStack>
    </Stack>
  );
};

export default CloudCard;
