import {IAAS_ROUTES, MAIN_ROUTES, PAAS_ROUTES} from "@/constants/routes";
import ManagedDatabases from "public/home/managed-db.svg";
import DevopsAutomation from "public/home/devops-auto.svg";
import ApplicationHosting from "public/home/application-hosting.svg";
import ManagedWordPress from "public/home/managed-wordpress.svg";
import WordPressService from "public/home/wordpress-service.svg";
import ApplicationMarketplace from "public/home/application-market.svg";
import CloudCompute from "public/home/cloud-compute.svg";
import Kubernetes from "public/home/kubernetes.svg";
import Storage from "public/home/storage.svg";
import HybridCloud from "public/home/hybrid-cloud.svg";
import VirtualDesktop from "public/home/virtual-desktop.svg";
import LoadBalancer from "public/home/load-balancer.svg";

import DeployPrototype from "public/home/deploy-prototype.svg";
import DeployInfinite from "public/home/deploy-infinite.svg";
import DeploySecure from "public/home/deploy-secure.svg";

import Document from "public/home/document.png";
import Server from "public/home/server.png";
import Browser from "public/home/browser.png";

export const INTRO_CONSTANTS = {
  TITLE: "The Ultimate Global Cloud Data Center",
  CONTENT:
    "One Stop Shop for Rapid Cloud Infrastructure, Cloud Hosting, Applications and Storage.",
  INTRO: "Introducing...",
  INTRO_TEXT: "The new high-margin cloud for the channel",
  START_HERE: "Start here",
  BUILD_GROW: "BUILD | GROW | CODE | MANAGE",
  BUILD_GROW_CONTENT:
    "Transform your workload with compute power, innovate with containers, secure your data with disaster recovery or grow your business with databases",
  CLOUD_INFRASTRUCTURE: "CLOUD INFRASTRUCTURE (IAAS)",
  APPLICATION_CLOUD: "APPLICATION CLOUD (PAAS)",
  CONTROL_PANEL: "Control Panel",
  CONTROL_PANEL_TEXT:
    "Spend more time coding and less time managing your infrastructure.",
  CONTROL_PANEL_SUBTEXT:
    "The CloudMyDc control panel makes server management simple and intuitive. Common tasks such as ordering servers, managing backups, and adjusting access control are only a few clicks away.",
  DEPLOY_INSTANCE_CMDC: "Deploy an instance with CloudMyDc!",
  DEPLOY_INSTANCE_CMDC_TEXT: "Over 45 million instances deployed by thousands of clients",
  ONE_CLICK_DEPLOY: " One-Click Deploy",
  POWERFUL_API: " Powerful API",
  EASY_MANAGEMENT: " Easy Management",
  ONE_CLICK_DEPLOY_TEXT:
    "The CloudMyDc control panel makes server management simple and intuitive. Common tasks such as ordering servers, managing backups, and adjusting access control are only a few clicks away.",
  POWERFUL_API_TEXT:
    "Bring automation to the forefront of your business using CloudMyDc API. Any action that you can perform on CloudMyDc can be automated into your systems.",
  VIEW_DOC: "View Product Documentation",
  VIEW_PRICING: "View Pricing",
  EASY_MANAGEMENT_TEXT:
    "The CloudMyDc control panel makes server management simple and intuitive. Common tasks such as ordering servers, managing backups, and adjusting access control are only a few clicks away.",
  INSIGHTS_RESOURCE: "Insights & Resources",
  INSIGHTS_RESOURCE_TEXT:
    "Insights and resources to help drive your business forward faster.",
};

export const CLOUD_INFRASTRUCTURE_CARD_DATA = [
  {
    image: CloudCompute,
    label: "Cloud Compute",
    content:
      "Amazon EC2 Alternate Optimized State-of-the Art  VMs with built-in  Enterprise NVMe SSD.",
    link: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.CLOUD_COMPUTE}`,
  },
  {
    image: Kubernetes,
    label: "Kubernetes",
    content:
      "Kubernetes as a Service allows you to get a production Kubernetes cluster in a minutes",
    link: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.KUBERNETES}`,
  },
  {
    image: Storage,
    label: "Storage",
    content:
      "Fully automated dedicated  Storage Clusters for   S3-Compatible Object Storage. Block Storage and file Storage",
    link: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.STORAGE}`,
  },
  {
    image: HybridCloud,
    label: "Hybrid Cloud",
    content: "The fast, affordable, easy to use cloud solution for service providers",
    link: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.HYBRID_CLOUD}`,
  },
  {
    image: VirtualDesktop,
    label: "Virtual Desktop Infrastructure",
    content: "Low-Cost High Speed optimized  Virtualized Desktop Environment",
    link: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.VIRTUAL_DESKTOP_INFRASTRUCTURE}`,
  },
  {
    image: LoadBalancer,
    label: "Load Balancers",
    content:
      "Integrated Load Balancer helps to manage traffic for high performance web services based on virtual machines",
    link: `${MAIN_ROUTES.IAAS}${IAAS_ROUTES.LOAD_BALANCERS}`,
  },
];

export const APPLICATION_CLOUD_CARD_DATA = [
  {
    image: ManagedDatabases,
    label: "Managed Databases",
    content: "Managed Database Services, built to scale across your favorite database",
    link: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.MANAGED_DATABASE}`,
  },
  {
    image: DevopsAutomation,
    label: "DevOps automation",
    content:
      "Certified software stacks for Java, PHP, Node.js, Ruby, Python, .NET and Go applications with automated scaling, clustering and updates",
    link: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.DEVOPS_AUTOMATION}`,
  },
  {
    image: ApplicationHosting,
    label: "Application Hosting",
    content:
      "Fully automated dedicated  Storage Clusters for   S3-Compatible Object Storage. Block Storage and file Storage",
    link: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.APPLICATION_HOSTING}`,
  },
  {
    image: ManagedWordPress,
    label: "Managed WordPress",
    content:
      "The fast, affordable Managed WordPress Service, you can deploy this as a single instance or cluster",
    link: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.MANAGED_WORD_PRESS_HOSTING}`,
  },
  {
    image: WordPressService,
    label: "WordPress as a Service",
    content: "The next-gen WordPress platform​ for hosting providers​​",
    link: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.WORD_PRESS_AS_A_SERVICE}`,
  },
  {
    image: ApplicationMarketplace,
    label: "Application Marketplace",
    content:
      "Ready-to-Go Applications, Tools and Services.Deploy prefigured apps and stacks in minutes",
    link: `${MAIN_ROUTES.PAAS}${PAAS_ROUTES.APPLICATION_MARKET_PLACE}`,
  },
];

export const DEPLOY_INSTANCE_CMDC_CARD_DATA = [
  {
    icon: DeployPrototype,
    label: "From prototype to production in seconds",
    content:
      "Our easy-to-use control panel and API let you spend more time coding and less time managing your infrastructure.",
    textInfo: "Explore our API",
  },
  {
    icon: DeployInfinite,
    label: "Infinite possibilities",
    content:
      "Choose from a number of preinstalled systems and applications to start your website in seconds, or upload your own ISO to unlock infinite possibilities.",
    textInfo: "View Operating Systems",
  },
  {
    icon: DeploySecure,
    label: "Secure and reliable",
    content:
      "We take pride in providing a secure and solid platform to our customers with 24/7/365 world-class technical support and a 100% uptime SLA.",
    textInfo: "Learn more about our SLA",
  },
];

export const ACCORDION_LIST_DATA = [
  {
    title: " How to Change my Photo from Admin Dashboard?",
    content:
      "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast",
  },
  {
    title: " How to Change my Password easily?",
    content:
      "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast",
  },
  {
    title: " How to Change my Subscription Plan using PayPal",
    content:
      "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast",
  },
  {
    title: " How to Change my Subscription Plan using PayPal",
    content:
      "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast",
  },
];

export const RESOURCE_CARD_LIST = [
  {image: Document, title: "Documentation"},
  {image: Server, title: "Server Status"},
  {image: Browser, title: "CloudMyDc APIs"},
];

export const HIGH_MARGIN_BOX = {
  BUTTON_TEXT: "START HERE",
  INTRO: "Introducing...",
  HIGH_MARGIN_CHANNEL: "The new high-margin cloud for the channel",
};
