"use client";
import React from "react";
import Image from "next/image";
import {AspectRatio, Center, Heading, HStack} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {INTRO_CONSTANTS} from "../../home.constants";
import CloudInfrastructureCards from "./cloudInfrastructureCards";
import ApplicationCloudCards from "./applicationCloudCards";

export const tablist = [
  {
    component: ApplicationCloudCards,
    label: ({isSelected}) => (
      <Center>
        <HStack spacing={2}>
          <AspectRatio ratio={1} w={"24px"} position={"relative"}>
            <Image
              alt="application cloud"
              src={
                isSelected
                  ? "/home/application-cloud-white.svg"
                  : "/home/application-cloud.svg"
              }
              fill
              style={{objectFit: "contain", objectPosition: "center"}}
            />
          </AspectRatio>
          <Heading fontWeight={600} variant={HEADING_VARIANTS.SUBTITLE3}>
            {INTRO_CONSTANTS.APPLICATION_CLOUD}
          </Heading>
        </HStack>
      </Center>
    ),
  },
  {
    component: CloudInfrastructureCards,
    label: ({isSelected}) => (
      <Center>
        <HStack spacing={2}>
          <AspectRatio ratio={1} position={"relative"} w={"24px"}>
            <Image
              alt="cloud infra structure"
              src={isSelected ? "/home/cloud-infra-white.svg" : "/home/cloud-infra.svg"}
              fill
              style={{objectFit: "contain", objectPosition: "center"}}
            />
          </AspectRatio>
          <Heading fontWeight={600} variant={HEADING_VARIANTS.SUBTITLE3}>
            {INTRO_CONSTANTS.CLOUD_INFRASTRUCTURE}
          </Heading>
        </HStack>
      </Center>
    ),
  },
];
