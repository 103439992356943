export const useImageDisplayCarousel = () => {
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    centerMode: false,
    infinite: true,
    pauseOnHover: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    swipeToSlide: true,
  };

  return {
    settings,
  };
};
