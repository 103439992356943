"use client";

import {SimpleGrid, VStack} from "@chakra-ui/react";
import Comparison from "./components/comparison";
import PriceComparisonFeatures from "./components/pricingComparisonFeatures";
import PriceComparisonHeader from "./components/pricingComparisonHeader";

const PriceComparison = () => {
  return (
    <VStack spacing={8}>
      <PriceComparisonHeader />
      <SimpleGrid columns={{base: 1, sm: 2}} spacing={4} w="full">
        <PriceComparisonFeatures />
        <Comparison />{" "}
      </SimpleGrid>
    </VStack>
  );
};

export default PriceComparison;
