"use client";
import React, {useState} from "react";
import Image from "next/image";
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import {TEXT_VARIANTS} from "@/theme/components/text";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {
  PRICE_COMPARE_CONSTANTS,
  PRICING_COMPARISON_DATA,
  PRICING_OPTIONS,
} from "../priceComparison.constants";

const Comparison = () => {
  const [CPUCount, setCPUCount] = useState(1);
  const selectedOption = PRICING_OPTIONS.find((data) => data.cpu === CPUCount);
  const filteredData = PRICING_COMPARISON_DATA.filter((data) => data.cpu === CPUCount);

  return (
    <Box w={"full"}>
      <Stack
        bg={"primary.200"}
        borderTopRightRadius={"16px"}
        borderTopLeftRadius={"16px"}
        color={"whiteAlpha.900"}
        p={{base: 4, sm: 6}}
        spacing={4}
      >
        <Stack>
          <Heading variant={HEADING_VARIANTS.SUBTITLE1}>
            {PRICE_COMPARE_CONSTANTS.BASIC_VIRTUAL_COST}
          </Heading>
          <Text variant={TEXT_VARIANTS.BODY1}>
            {PRICE_COMPARE_CONSTANTS.BASIC_VIRTUAL_COST_DESC}
          </Text>
        </Stack>
        <HStack>
          {PRICING_OPTIONS.map((data, index) => {
            const isActive = CPUCount === data.cpu;
            return (
              <Button
                key={index}
                bg={isActive ? "#3493f1" : ""}
                color={"whiteAlpha.900"}
                onClick={() => setCPUCount(data.cpu)}
                _hover={{bg: "#6495ED"}}
              >
                {data.title}
              </Button>
            );
          })}
        </HStack>
      </Stack>
      <Stack
        borderWidth={"1px"}
        boxShadow={"lg"}
        borderBottomLeftRadius={"16px"}
        borderBottomRightRadius={"16px"}
        p={{base: 4, sm: 6}}
        spacing={6}
      >
        <Center>
          <Box borderRadius={"8px"} bg={"gray.100"} p={"20px"}>
            <Text textAlign={"center"} variant={TEXT_VARIANTS.BODY2}>
              {selectedOption?.desc}
            </Text>
          </Box>
        </Center>
        {filteredData.map((list, index) => {
          return (
            <Stack
              alignItems="center"
              borderBottomWidth={"1px"}
              borderBottomStyle={"dotted"}
              direction={{base: "column", md: "row"}}
              key={index}
              justify={"space-between"}
              pb={6}
              w={"full"}
              _last={{border: "none"}}
            >
              <HStack alignItems="center" spacing={4}>
                <AspectRatio ratio={1} width={"50px"}>
                  <Image alt="" fill src={list.image} style={{objectFit: "contain"}} />
                </AspectRatio>
                <Text variant={TEXT_VARIANTS.BODY2}>{list.title}</Text>
              </HStack>
              <Heading
                color={list?.title === "CloudMyDC" ? "primary.200" : "red"}
                variant={HEADING_VARIANTS.SUBTITLE1}
              >
                {list.price}
              </Heading>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

export default Comparison;
