import {Heading, Highlight, Text, VStack} from "@chakra-ui/react";
import {TEXT_VARIANTS} from "@/theme/components/text";
import {PRICE_COMPARE_CONSTANTS} from "../priceComparison.constants";

const PriceComparisonHeader = () => {
  return (
    <VStack>
      <Heading fontWeight={"400"} textAlign={"center"}>
        <Highlight styles={{fontWeight: 700}} query={"less Money"}>
          {PRICE_COMPARE_CONSTANTS.HEADING}
        </Highlight>
      </Heading>
      <Text
        color={"text.100"}
        textAlign={"center"}
        variant={TEXT_VARIANTS.BODY2}
        w={{base: "full", md: "40%"}}
      >
        {PRICE_COMPARE_CONSTANTS.CONTENT}
      </Text>
    </VStack>
  );
};

export default PriceComparisonHeader;
