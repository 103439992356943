import AWSLogo from "public/pricing/aws-logo.svg";
import AzureLogo from "public/pricing/azure-logo.svg";
import GCPLogo from "public/pricing/gcp-logo.svg";
import CMLLogo from "public/brand/logo.png";

export const PRICE_COMPARE_CONSTANTS = {
  BASIC_VIRTUAL_COST: "Basic virtual machine costs",
  BASIC_VIRTUAL_COST_DESC: "Easy, fast, and flexible compute built for a range of needs.",
  COMPARISON_TITLE: "See What You Can Save",
  CONTENT:
    "CloudMyDc offers globally available, an enterprise-grade infrastructure, for just a fraction of the cost of the Big Tech clouds.",
  HEADING: "More Cloud Less Money",
};

export const FEATURES_LIST = [
  "Save up to 70% on your existing costs",
  "We don't charge US partners for transfer and bandwidth",
  "Pay only for the features you need",
  "24/7 support, with a team you can actually talk to",
  "Avoid unnecessary fees and add-ons",
];

export const PRICING_OPTIONS = [
  {cpu: 1, desc: "1 GB Memory / 25 GB Storage / 1,000 GB Bandwidth", title: "1 CPU"},
  {cpu: 2, desc: "4 GB Memory / 80 GB Storage / 4,000 GB Bandwidth", title: "2 CPUs"},
  {cpu: 4, desc: "8 GB Memory / 160 GB Storage / 5,000 GB Bandwidth", title: "4 CPUs"},
  {
    cpu: 8,
    desc: "16 GB Memory / 320 GB Storage / 6,000 GB Bandwidth",
    title: "8 CPUs",
  },
];

export const PRICING_COMPARISON_DATA = [
  {
    cpu: 1,
    image: CMLLogo,
    price: "$20.00",
    title: "CloudMyDC",
  },
  {
    cpu: 1,
    image: GCPLogo,
    price: "$92.00",
    title: "GCP",
  },
  {
    cpu: 1,
    image: AWSLogo,
    price: "$98.00",
    title: "AWS",
  },
  {
    cpu: 1,
    image: AzureLogo,
    price: "$79.00",
    title: "Azure",
  },
  {
    cpu: 2,
    image: CMLLogo,
    price: "$37.43",
    title: "CloudMyDC",
  },
  {
    cpu: 2,
    image: GCPLogo,
    price: "$367.00",
    title: "GCP",
  },
  {
    cpu: 2,
    image: AWSLogo,
    price: "$399.00",
    title: "AWS",
  },
  {
    cpu: 2,
    image: AzureLogo,
    price: "$342.00",
    title: "Azure",
  },
  {
    cpu: 4,
    image: CMLLogo,
    price: "$85.00",
    title: "CloudMyDC",
  },
  {
    cpu: 4,
    image: GCPLogo,
    price: "$480.00",
    title: "GCP",
  },
  {
    cpu: 4,
    image: AWSLogo,
    price: "$521.00",
    title: "AWS",
  },
  {
    cpu: 4,
    image: AzureLogo,
    price: "$452.00",
    title: "Azure",
  },
  {
    cpu: 8,
    image: CMLLogo,
    price: "$149.7",
    title: "CloudMyDC",
  },
  {
    cpu: 8,
    image: GCPLogo,
    price: "$620.00",
    title: "GCP",
  },
  {
    cpu: 8,
    image: AWSLogo,
    price: "$674.00",
    title: "AWS",
  },
  {
    cpu: 8,
    image: AzureLogo,
    price: "$593.00",
    title: "Azure",
  },
];
