"use client";

import React from "react";
import Image from "next/image";
import {AspectRatio, Center, HStack, Heading} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";
import {INTRO_CONSTANTS} from "../../home.constants";
import OneClickDeploy from "./oneClickDeploy";
import EasyManagement from "./easyManagement";

export const tablist = [
  {
    component: OneClickDeploy,
    label: ({isSelected}) => (
      <Center>
        <HStack spacing={2}>
          <AspectRatio ratio={1} w={"24px"}>
            <Image
              alt="one click deploy"
              src={
                isSelected
                  ? "/home/one-click-deploy-white.svg"
                  : "/home/one-click-deploy.svg"
              }
              fill
            />
          </AspectRatio>
          <Heading fontWeight={600} variant={HEADING_VARIANTS.SUBTITLE3}>
            {INTRO_CONSTANTS.ONE_CLICK_DEPLOY}
          </Heading>
        </HStack>
      </Center>
    ),
  },
  {
    component: EasyManagement,
    label: ({isSelected}) => (
      <Center>
        <HStack spacing={2}>
          <AspectRatio ratio={1} w={"24px"}>
            <Image
              alt="easy management"
              src={
                isSelected
                  ? "/home/easy-management-white.svg"
                  : "/home/easy-management.svg"
              }
              fill
            />
          </AspectRatio>
          <Heading fontWeight={600} variant={HEADING_VARIANTS.SUBTITLE3}>
            {INTRO_CONSTANTS.EASY_MANAGEMENT}
          </Heading>
        </HStack>
      </Center>
    ),
  },
];
