import Aicpa from "public/certificates/aicpa.webp";
import Gdpr from "public/certificates/gdpr.webp";
import Ccpa from "public/certificates/ccpa.webp";

export const CERTIFICATE_CONSTANTS = {TITLE: "Our Certifications"};

export const CERTIFICATES = [
  {
    name: "aicpa",
    image: Aicpa,
  },
  {
    name: "gdpr",
    image: Gdpr,
  },
  {
    name: "ccpa",
    image: Ccpa,
  },
];
