"use client";
import React from "react";
import Image from "next/image";
import {AspectRatio} from "@chakra-ui/react";
import {Carousel} from "@onemind-services-llc/ui-components";
import {useImageDisplayCarousel} from "../../hooks/useImageDIsplayCarousel";

type Props = {
  images: string[];
};

const ImageDisplay = (props: Props) => {
  const {images} = props;
  const {settings} = useImageDisplayCarousel();
  return (
    <Carousel settings={settings}>
      {images.map((image, index) => {
        return (
          <AspectRatio key={index} position={"relative"} ratio={12 / 6} width={"full"}>
            <Image
              alt=""
              fill
              src={image}
              style={{objectFit: "contain", objectPosition: "center"}}
            />
          </AspectRatio>
        );
      })}
    </Carousel>
  );
};

export default ImageDisplay;
