import React from "react";
import {Center, Highlight, Stack, Text} from "@chakra-ui/react";
import {TEXT_VARIANTS} from "@/theme/components/text";
import {INTRO_CONSTANTS} from "../../home.constants";
import ImageDisplay from "./imageDisplay";

const EasyManagement = () => {
  const images = ["/home/vhi1.png", "/home/vhi2.png", "/home/vhi3.png"];
  return (
    <Stack p={{base: 4, md: 8}} spacing={8}>
      <ImageDisplay images={images} />
      <Center padding={"30px"}>
        <Text textAlign={"center"} variant={TEXT_VARIANTS.BODY2}>
          <Highlight query={["The CloudMyDc"]} styles={{fontWeight: 600}}>
            {INTRO_CONSTANTS.CONTROL_PANEL_SUBTEXT}
          </Highlight>
        </Text>
      </Center>
    </Stack>
  );
};

export default EasyManagement;
