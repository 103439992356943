"use client";
import React from "react";
import Image from "next/image";
import {Box, Stack, VStack, useColorModeValue} from "@chakra-ui/react";
import Title from "@/components/title";
import {CERTIFICATES, CERTIFICATE_CONSTANTS} from "./certificate.constants";

const Certificates = () => {
  const borderColor = useColorModeValue("black", "gray.600");
  return (
    <VStack spacing={4}>
      <Title title={CERTIFICATE_CONSTANTS.TITLE} withDivider={false} />
      <Stack
        borderRadius={"8px"}
        className="brand-shadow"
        direction={{base: "column", sm: "row"}}
        mt={6}
        p={4}
        spacing={6}
      >
        {CERTIFICATES.map((certificate, index) => {
          return (
            <Box
              borderColor={borderColor}
              borderRadius={"8px"}
              borderWidth={"1px"}
              key={index}
              p={4}
            >
              <Box
                height={{base: "200px", sm: "150px", md: "200px"}}
                position="relative"
                width={{base: "200px", sm: "150px", md: "200px"}}
              >
                <Image
                  alt={certificate.name}
                  title={certificate.name}
                  fill
                  placeholder="blur"
                  src={certificate.image}
                  sizes={"(max-width: 200px) 100vw, 200px"}
                  style={{objectFit: "contain"}}
                />
              </Box>
            </Box>
          );
        })}
      </Stack>
    </VStack>
  );
};

export default Certificates;
