"use client";
import React, {useState} from "react";
import {
  Box,
  ResponsiveValue,
  SimpleGrid,
  Stack,
  Tabs as ChakraTabs,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

type Props = {
  columns?: ResponsiveValue<number>;
  tabList: {
    component: React.ComponentType;
    label: React.FunctionComponent<{isSelected: boolean}>;
  }[];
};

const Tabs = (props: Props) => {
  const {columns = {base: 1, sm: 3}, tabList} = props;
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Stack spacing={6}>
      <SimpleGrid columns={columns} spacing={2} w="full">
        {tabList.map((tab, index) => {
          const isSelected = index === tabIndex;
          const {label: Label} = tab;
          return (
            <Box
              bg={isSelected ? "primary.200" : "transparent"}
              borderColor="primary.200"
              borderRadius={"8px"}
              borderWidth={"1px"}
              boxSize={"border-box"}
              className="brand-shadow"
              color={isSelected ? "white" : "inherit"}
              cursor="pointer"
              key={index}
              minH={"3rem"}
              onClick={() => setTabIndex(index)}
              p={3}
              transition={"background-color 0.1s ease"}
              w="full"
              _hover={{borderWidth: "1px"}}
            >
              <Label isSelected={isSelected} />
            </Box>
          );
        })}
      </SimpleGrid>
      <ChakraTabs index={tabIndex}>
        <TabPanels>
          {tabList.map((tab, index) => {
            const {component: Component} = tab;
            return (
              <TabPanel key={index} p={0}>
                <Component />
              </TabPanel>
            );
          })}
        </TabPanels>
      </ChakraTabs>
    </Stack>
  );
};

export default Tabs;
