import React from "react";
import {SimpleGrid} from "@chakra-ui/react";
import {CLOUD_INFRASTRUCTURE_CARD_DATA} from "../../home.constants";
import CloudCard from "./cloudCard";

const CloudInfrastructureCards = () => {
  return (
    <SimpleGrid columns={{base: 1, sm: 2, md: 3}} spacing={6}>
      {CLOUD_INFRASTRUCTURE_CARD_DATA.map((data, index) => {
        return (
          <CloudCard
            content={data.content}
            image={data.image}
            key={index}
            label={data.label}
            link={data.link}
          />
        );
      })}
    </SimpleGrid>
  );
};

export default CloudInfrastructureCards;
