import React from "react";
import {Divider, Heading, VStack} from "@chakra-ui/react";
import {HEADING_VARIANTS} from "@/theme/components/heading";

type Props = {
  color?: string;
  isSubTitle?: boolean;
  spacing?: number;
  title: string;
  withDivider?: boolean;
};

const Title = (props: Props) => {
  const {color, isSubTitle = false, spacing = 2, title, withDivider = true} = props;
  const headingVariant = isSubTitle
    ? HEADING_VARIANTS.SUBTITLE2
    : HEADING_VARIANTS.HEADLINE3;

  const baseHeadingVariant = isSubTitle
    ? HEADING_VARIANTS.SUBTITLE2
    : HEADING_VARIANTS.SUBTITLE1;
  return (
    <VStack spacing={spacing}>
      <Heading
        textAlign={"center"}
        variant={{base: baseHeadingVariant, md: headingVariant}}
        color={color}
      >
        {title}
      </Heading>
      {withDivider && (
        <Divider
          width={"50px"}
          borderWidth={"2px"}
          borderColor={color || "primary.200"}
          opacity={1}
        />
      )}
    </VStack>
  );
};

export default Title;
